import React, { useEffect } from 'react'
import { Link, navigate } from 'gatsby'
import { graphql } from 'gatsby'
import urlSlug from 'url-slug'
import Layout from '../../components/layout'
import Seo from '../../components/seo'

import Pager from '../../components/pager.js'
import ReactMarkdown from 'react-markdown'
import { getDateFormatted } from '../../helpers/getDateFormatted'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import pictureFeedSchedule from '../../images/picture-schedule.jpg'
import Carousels from '../../components/carousel'
import { useForm } from '../../hooks/useForm'
import { DIARY_GENERAL_ROUTE } from '../../utils/variables/generalRoutes'

const diariesAll = ({ data, pageContext }) => {
  const [valuesFilter, handleInputChange] = useForm({
    categorySelected: 'Todas',
    organizationSelected: 'Todas'
  })

  const {
    allStrapiDiary: { nodes: diaries },
    strapiInstitution: institution
  } = data

  const {
    mainNavbar,
    title,
    diariesCategoriesData,
    organizationalUnits
  } = pageContext

  const { categorySelected, organizationSelected } = valuesFilter

  const objCarousel = {
    visible: true,
    slide: institution.mainCover?.diariesCover
  }


  const {
    data: {
      allStrapiOrganizationalUnit: { nodes: organizations }
    }
  } = organizationalUnits

  const isHomeUcc = false //TODO esto no aplica
  const page = {
    slug: null,
    ...mainNavbar
  }
  useEffect(() => {
    if (categorySelected.toLowerCase() !== 'todas') {
      navigate(`/${DIARY_GENERAL_ROUTE}/${urlSlug(categorySelected)}/`, {
        replace: false
      })
    }
  }, [categorySelected])

  useEffect(() => {
    if (organizationSelected.toLowerCase() !== 'todas') {
      navigate(`/${urlSlug(organizationSelected)}/${DIARY_GENERAL_ROUTE}/`, {
        replace: false
      })
    }
  }, [organizationSelected])

  return (
    <Layout page={page} logo={page.logo}>
      <Seo title={`Agenda ${title}`} />
      <section className="schedule-feed mb-3">
        {objCarousel?.slide && objCarousel?.slide.length > 0 ? 
          <div className="d-flex flex-column schedule-header-carousel">
            <Carousels carousel={objCarousel} keyName={'scheduleFeed-'} />
          </div>
        : null}
        <div className="container">
          <div className="my-5">
            <div className="d-flex align-items-center justify-content-center row mb-4">
              <div className="col-12 col-md-6 mb-auto">
                <h5 className="text-uppercase title-secondary">
                  Agenda <span className="font-weight-bold text-main">UCC</span>
                </h5>
              </div>
              <form className="col-12 col-md-6 ">
                <div className="d-flex feed-form">
                  <select
                    className="w-100 form-control form-control-sm "
                    onChange={handleInputChange}
                    name="organizationSelected"
                    defaultValue="-1"
                  >
                    <option value="-1" disabled>
                      Por Entidad
                    </option>
                    <option value="Todas">Todas</option>
                    {organizations.map((organization) => (
                      <option
                        value={organization.slug}
                        key={organization.strapiId}
                      >
                        {organization.name}
                      </option>
                    ))}
                  </select>
                  <select
                    className="w-100 form-control form-control-sm"
                    onChange={handleInputChange}
                    name="categorySelected"
                    defaultValue="-1"
                  >
                    <option value="-1" disabled>
                      Por Categoría
                    </option>
                    <option value="Todas">Todas</option>
                    {diariesCategoriesData.map((category) => (
                      <option value={category.name} key={category.strapiId}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
              </form>
            </div>
            <div className=" row ">
              {diaries?.map((diary) => {
                const objDate = getDateFormatted(new Date(diary.date))

                return (
                  <div
                    className={
                      'col-12 col-xl-3 col-lg-4 col-md-6 col-sm-6 pb-4 mb-3 d-flex align-items-stretch'
                    }
                    key={diary.strapiId}
                  >
                    <div
                      key={`diaresId-${diary.strapiId}`}
                      className={
                        'schedule-card-wrapper mx-2 p-4 h-100 rounded shadow-sm bg-white'
                      }
                      style={{ minHeight: 292 + 'px' }}
                    >
                      <Link
                        to={`/agenda/${diary?.slug}`}
                        className="text-decoration-none"
                      >
                        <div className="text-center text-uppercase d-flex flex-column align-items-center justify-content-center py-2 border-bottom">
                          <h6
                            className={`font-weight-bold ${
                              isHomeUcc ? 'ucc-text-blue' : ' '
                            }`}
                          >
                            {objDate.day}
                          </h6>
                          <h1
                            className={`font-weight-bold ${
                              isHomeUcc ? 'ucc-text-blue' : ' '
                            }`}
                          >
                            {objDate.numberDay}
                          </h1>
                          <h6
                            className={`font-weight-bold ${
                              isHomeUcc ? 'ucc-text-blue' : ' '
                            }`}
                          >
                            {objDate.mounth}
                          </h6>
                        </div>
                        <div className="h-50 text-uppercase d-flex flex-column align-items-stretch justify-content-between pt-2">
                          <ReactMarkdown
                            escapeHtml={false}
                            source={diary.title}
                            className={`font-weight-bold ${
                              isHomeUcc ? 'ucc-text-blue' : ' '
                            }`}
                          />
                          <h6>
                            <FontAwesomeIcon
                              icon={['fas', 'map-marker-alt']}
                              size="lg"
                              className={`mr-1 ${
                                isHomeUcc ? 'ucc-text-blue' : ''
                              }`}
                            />
                            <span
                              className={`font-weight-bold text-capitalize ${
                                isHomeUcc ? 'ucc-text-blue' : ''
                              }`}
                            >
                              {diary.place}
                            </span>
                          </h6>
                        </div>
                      </Link>
                    </div>
                  </div>
                )
              })}
            </div>
            <Pager pageContext={pageContext} />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default diariesAll

export const eventListQuery = graphql`
  query($skip: Int, $limit: Int, $date: Date) {
    allStrapiDiary(
      skip: $skip
      limit: $limit
      sort: { fields: date, order: ASC }
      filter: { date: { gte: $date } }
    ) {
      nodes {
        strapiId
        title
        slug
        date(formatString: "YYYY/MM/DD", locale: "es")
        time
        place
        diary_category {
          name
        }
      }
    }
    strapiInstitution {
      mainCover {
        diariesCover {
          content
          imageDescription
          name
          visible
          url
          backgroundImage {
            url
          }
          backgroundVideo {
            url
          }
          diary {
            slug
          }
          novelty {
            slug
          }
          organizational_unit {
            slug
          }
          page {
            slug
          }
          ucc_career {
            slug
          }
        }
      }
    }
  }
`
